import React from 'react';

const UxUiMoreReferrals = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.5654 25.8486H21.2462L13.6481 22.8794L6.01799 25.8486C5.89967 25.9101 5.76828 25.9421 5.6349 25.9421C5.50153 25.9421 5.37014 25.9101 5.25182 25.8486C5.13868 25.7727 5.04866 25.6667 4.99228 25.5427C4.93589 25.4186 4.91546 25.2811 4.93265 25.1459L5.37965 16.9734L0.207435 10.5884C0.113537 10.4883 0.0483363 10.3649 0.0184262 10.231C-0.0114838 10.0971 -0.00488448 9.95776 0.037522 9.82728C0.0799284 9.6968 0.156741 9.57974 0.259656 9.48901C0.362571 9.39828 0.487707 9.33702 0.622474 9.3113L8.54018 7.23611L12.9458 0.340215C13.0262 0.23437 13.13 0.148712 13.249 0.0896329C13.3681 0.0305542 13.4992 0 13.6321 0C13.765 0 13.8961 0.0305542 14.0152 0.0896329C14.1343 0.148712 14.238 0.23437 14.3184 0.340215L18.724 7.23611L26.6414 9.3113C26.7761 9.33702 26.9017 9.39828 27.0046 9.48901C27.1075 9.57974 27.1843 9.6968 27.2267 9.82728C27.2691 9.95776 27.2753 10.0971 27.2454 10.231C27.2155 10.3649 27.1503 10.4883 27.0564 10.5884L21.8846 16.9734L22.3316 25.1459C22.3488 25.2811 22.3279 25.4186 22.2716 25.5427C22.2152 25.6667 22.1255 25.7727 22.0124 25.8486C21.8667 25.893 21.7111 25.893 21.5654 25.8486ZM13.6481 21.1553H13.9353L20.6714 23.7734L20.2564 16.5583C20.2514 16.3503 20.3193 16.1469 20.4481 15.9835L25.0451 10.3647L18.0218 8.54514C17.8318 8.47642 17.6655 8.35456 17.5428 8.19401L13.6481 2.09624L9.72139 8.19401C9.59874 8.35456 9.43243 8.47642 9.24243 8.54514L2.21872 10.3647L6.81611 15.9835C6.94489 16.1469 7.01243 16.3503 7.00746 16.5583L6.59242 23.7734L13.3289 21.1553H13.6481Z"
      fill="#FFF500"
    />
    <path
      d="M17.7966 36.0289H9.46426C9.34721 36.0355 9.23038 36.0125 9.12443 35.9623C9.01848 35.9121 8.92697 35.8364 8.85787 35.7417C8.77784 35.6579 8.71852 35.5565 8.68523 35.4455C8.65194 35.3346 8.64543 35.217 8.66614 35.103L10.4541 22.78C10.4838 22.5683 10.596 22.3772 10.7667 22.2484C10.9373 22.1197 11.1524 22.0641 11.3641 22.0937C11.5758 22.1234 11.7669 22.236 11.8957 22.4067C12.0244 22.5773 12.08 22.792 12.0504 23.0037L10.3583 34.4327H16.903L15.2109 23.0037C15.1813 22.792 15.2369 22.5773 15.3656 22.4067C15.4943 22.236 15.6855 22.1234 15.8972 22.0937C16.1089 22.0641 16.3236 22.1197 16.4942 22.2484C16.6648 22.3772 16.7775 22.5683 16.8071 22.78L18.5947 35.103C18.6154 35.217 18.6089 35.3346 18.5756 35.4455C18.5424 35.5565 18.4834 35.6579 18.4034 35.7417C18.3343 35.8364 18.2424 35.9121 18.1364 35.9623C18.0305 36.0125 17.9137 36.0355 17.7966 36.0289Z"
      fill="#FCFCFD"
    />
    <path
      d="M21.9452 40.6236H5.3124C5.10073 40.6236 4.89778 40.5398 4.74811 40.3902C4.59843 40.2405 4.51428 40.0372 4.51428 39.8255C4.46659 39.1448 4.55758 38.4615 4.78162 37.8169C5.00566 37.1724 5.35785 36.5801 5.81747 36.0757C6.27708 35.5713 6.83439 35.1656 7.45541 34.8828C8.07643 34.6 8.74824 34.446 9.43045 34.4304C9.63997 34.4297 9.84197 34.5084 9.99592 34.6505C10.1499 34.7927 10.2444 34.9876 10.2605 35.1965C10.2607 35.4112 10.1779 35.6177 10.029 35.7725C9.88022 35.9273 9.67697 36.0183 9.46241 36.0266C8.66084 36.067 7.8999 36.3908 7.31512 36.9405C6.73033 37.4902 6.35976 38.2298 6.26992 39.0274H21.179C21.0892 38.2298 20.7186 37.4902 20.1338 36.9405C19.549 36.3908 18.7881 36.067 17.9865 36.0266C17.8817 36.0225 17.7786 35.9977 17.6833 35.9537C17.588 35.9098 17.5023 35.8477 17.4312 35.7706C17.36 35.6935 17.3049 35.6029 17.2687 35.5044C17.2325 35.4059 17.2161 35.3014 17.2203 35.1965C17.2202 35.0932 17.241 34.9908 17.2815 34.8957C17.322 34.8006 17.3812 34.7147 17.4557 34.6431C17.5303 34.5716 17.6186 34.5156 17.7153 34.4791C17.8119 34.4425 17.9152 34.4261 18.0185 34.4304C18.7007 34.446 19.3725 34.6 19.9935 34.8828C20.6145 35.1656 21.1718 35.5713 21.6314 36.0757C22.0911 36.5801 22.4436 37.1724 22.6677 37.8169C22.8917 38.4615 22.9827 39.1448 22.935 39.8255C22.9386 39.9485 22.9136 40.071 22.8621 40.1828C22.8107 40.2947 22.7339 40.3932 22.6381 40.4704C22.5422 40.5477 22.4297 40.6017 22.3095 40.6283C22.1893 40.6549 22.0647 40.6532 21.9452 40.6236Z"
      fill="#FCFCFD"
    />
    <path
      d="M23.894 48.0002H3.36648C3.15481 48.0002 2.95147 47.916 2.80179 47.7663C2.65212 47.6167 2.56836 47.4137 2.56836 47.202V39.7317C2.56836 39.52 2.65212 39.3171 2.80179 39.1674C2.95147 39.0177 3.15481 38.9336 3.36648 38.9336H23.894C24.1057 38.9336 24.3086 39.0177 24.4583 39.1674C24.608 39.3171 24.6921 39.52 24.6921 39.7317V47.202C24.6921 47.4137 24.608 47.6167 24.4583 47.7663C24.3086 47.916 24.1057 48.0002 23.894 48.0002ZM4.16461 46.4039H23.0959V40.5298H4.16461V46.4039Z"
      fill="#FCFCFD"
    />
  </svg>
);

export default UxUiMoreReferrals;
